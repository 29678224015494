.navbar {
    padding: 0;
}


.navbar .nav-link,
.navbar .nav-link:hover,
.navbar .nav-link:active {
    color: #FFF;
    background-color: transparent;
}

.navbar-brand img {
    width: 80px;
}

.navbar-brand-footer img {
    width: 80px;
    height: auto;
}


@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');




#mobile-three-line-collapse {
    font-size: 40px;
    margin-left: 30px;
    margin-top: 30px;
}

#mobile-close-collapse {
    font-size: 20px;
    color: white;
}

#mySidenav {
    transition: 0.5s;
}



/* ============ desktop view ============ */
@media all and (min-width: 992px) {

    #mobile-close-arrow-left {
        font-size: 18px;
        position: relative;
        left: 14px;
        top: 10px;
        border: 1px solid rgba(0, 0, 0, 0.411);
        border-radius: 50%;
        background: rgb(166, 188, 255);
        padding: 2px 6px;
        line-height: 18px;
        cursor: pointer;
    }

    #mobile-close-arrow-right {
        font-size: 18px;
        position: relative;
        left: 14px;
        top: 10px;
        border: 1px solid rgba(0, 0, 0, 0.411);
        border-radius: 50%;
        background: rgb(166, 188, 255);
        padding: 2px 6px;
        line-height: 18px;
        cursor: pointer;
    }



    .navbar-brand img {
        width: 120px;
    }

    .navbar-brand-footer img {
        width: 80px;
        height: auto;
    }

    .timeline-image-arrow {
        width: 86%;
        margin: auto;
    }


    #mobile-close-collapse {
        display: none;
    }

    #mobile-three-line-collapse {
        display: none;
    }


    .dashboard-profile-staked-2 .nav-link {
        padding: 0px;
        text-decoration: underline;
        font-size: 14px;
    }

    .dashboard-profile-staked-2 .nav-pills .nav-link.active,
    .dashboard-profile-staked-2 .nav-pills .show>.nav-link {
        color: #0d6efd;
        background-color: transparent;
    }

    .form-control-stake-deposit-address,
    .form-control-stake-deposit-address:focus {
        width: 80%;
        margin: auto;
        box-shadow: #000 0px 15px 20px -20px;
        padding: 1rem 0.75rem;
        font-size: 0.9rem;
        border: 1px solid #8a8a8a8e;
        background: white;
        color: #000;
    }


    .dashboard-profile-text-4 {
        font-size: 14px;
        color: rgb(0, 119, 255) !important;
        text-decoration: underline;
    }

    .dashboard-profile-text-3 {
        font-size: 13px;
        margin-bottom: 6px;
    }

    .dashboard-profile-text-2 {
        font-size: 14px;
        margin-bottom: 6px;
    }

    .dashboard-profile-staked-2 .card {
        border-bottom: 6px solid #15db95;
        background-color: #e0e1e233;
    }

    .dashboard-profile-text-2 {
        font-size: 14px;
        font-weight: 600;
    }

    .dashboard-profile-text-1 {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
    }

    .dashboard-profile-staked-1 {
        background-color: #e0e1e233;
        padding: 20px 20px;
        border-radius: 4px;
        border-left: 5px solid #1668dd !important;
    }

    .btn-profile-update {
        background-color: #8a8a8a;
        padding: 8px 20px;
        color: #FFF;
        border: 1px solid #8a8a8a;
        border-radius: 0.25rem;
    }

    .dashboard-profile-section .form-control-address {
        padding: 0.600rem 0.75rem;
        font-size: 0.9rem;
        font-weight: 400;
        line-height: 1.5;
        color: #000;
        background-color: white;
        background-clip: padding-box;
        border-bottom: 1px solid #ced4da !important;
        border-radius: 0rem;
        box-shadow: none !important;
        border: none;
    }

    .dashboard-profile-section .form-control-name {
        padding: 0.600rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #000;
        background-color: white;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        box-shadow: none !important;
        border-right: 0px;
    }

    .avatar-upload {
        position: relative;
        max-width: 205px;
    }

    .avatar-upload .avatar-edit {
        position: absolute;
        right: 12px;
        z-index: 1;
        top: 10px;
    }

    .avatar-upload .avatar-edit input {
        display: none;
    }

    .avatar-upload .avatar-edit input+label {
        display: inline-block;
        width: 34px;
        height: 34px;
        margin-bottom: 0;
        border-radius: 100%;
        background: #15db96;
        border: 1px solid transparent;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        cursor: pointer;
        font-weight: normal;
        transition: all 0.2s ease-in-out;
    }

    .avatar-upload .avatar-edit input+label:after {
        content: "\f067";
        font-family: 'FontAwesome';
        color: #FFF;
        position: absolute;
        top: 4px;
        left: 0;
        right: 0;
        text-align: center;
        margin: auto;
    }

    .avatar-upload .avatar-preview {
        width: 192px;
        height: 192px;
        position: relative;
        border-radius: 100%;
        border: 6px solid #F8F8F8;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    }

    .avatar-upload .avatar-preview>div {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }


    .dashboard-sidenav-section .nav-pills .nav-link.active,
    .dashboard-sidenav-section .nav-pills .show>.nav-link {
        background-color: transparent;
        background-image: url('../image/Rectangle29.png');
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        color: #ffffff !important;
        position: relative;

    }

    .dashboard-sidenav-section .nav-link.active::after {
        content: "";
        position: absolute;
        background-color: transparent;
        bottom: 100%;
        right: 0;
        /* height: 35px;
        width: 35px; */
        /* border-bottom-right-radius: 18px; */
        box-shadow: 0 20px 0 0 white;
    }

    .dashboard-sidenav-section .nav-link.active::before {
        content: "";
        position: absolute;
        background-color: transparent;
        top: 100%;
        right: 0;
        /* height: 35px;
        width: 35px; */
        /* border-top-right-radius: 18px; */
        box-shadow: 0 -20px 0 0 white;
    }

    .dashboard-navbar-brand img {
        width: 80px;
        height: 80px;
        margin: auto;
        text-align: center;
        margin-bottom: 30px;
        margin-top: 20px;
    }

    .dashboard-sidenav-section .nav-pills .nav-link {
        padding: 13px 15px;
        text-decoration: none;
        font-size: 15px;
        color: white !important;
        display: block;
        margin-top: 10px;
        border-radius: 0px;
        text-align: left;
        margin-left: 10px;
        cursor: pointer !important;
        /* border-top-left-radius: 50px;
        border-bottom-left-radius: 50px; */

    }

    .dashboard-sidenav-section .nav-link .bi {
        margin-right: 20px;
        font-size: 22px;
        margin-left: 10px;
        position: relative;
        top: 2px;
    }

    .dashboard-main-navbar {
        margin-left: 400px;
    }

    .dashboard-sidenav-section {
        height: 100%;
        width: 250px;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        /* background: #F5F5F5; */
        /* background: linear-gradient(107deg, rgb(255 255 255) 11.1%, rgb(255 205 55) 95.3%); */
        /* background: var(--Linear, linear-gradient(1deg, #BC9018 0.5%, rgba(255, 255, 255, 0.00) 97.65%)); */
        background: linear-gradient(147deg, #3d3423 25.1%, rgb(241 191 130) 95.3%) !important;
        color: #000 !important;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);


        /* overflow: hidden; */
    }

    .dashboard-main-section {
        transition: 0.6s;
        margin-left: 250px;
    }


    .auth-form__btn {
        text-transform: uppercase;
        background: transparent;
        border: 1px solid #EEE;
        padding: 10px 20px;
        width: 40%;
        font-size: 16px;
        color: #FFF;
        text-transform: uppercase;
        letter-spacing: 1px;
        border-radius: 6px;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    }

    .login-banner-tabs-section {
        min-height: 100vh;
    }



    .login-banner-tabs-section ::placeholder {
        color: #FFF
    }

    .form-control-login {
        display: block;
        width: 50%;
        margin: auto;
        padding: 0.675rem 0.75rem;
        font-size: 1.1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #FFF;
        background-color: #15db95;
        background-clip: padding-box;
        border: none !important;
        outline: none !important;
        border-radius: 0.25rem;
    }

    .tabs-automatic-login-button {
        background: rgb(78, 119, 241);
        padding: 10px 20px;
        width: 40%;
        font-size: 16px;
        color: #FFF;
        text-transform: uppercase;
        letter-spacing: 1px;
        border-radius: 6px;
        border: none;
        box-shadow: #011f4a 0px 25px 50px -12px;

    }

    .login-tabs-section .nav-pills {
        background: rgb(78, 119, 241);
        width: max-content;
        margin: auto;
        padding: 5px 5px;
        border-radius: 50px;
        box-shadow: #011f4a 0px 25px 50px -12px;

    }

    .login-tabs-section .nav-pills .nav-link {
        color: #FFF;
        padding: 10px 30px;
        border-radius: 50px;
    }

    /* 
    .login-tabs-section .nav-pills .nav-link.active,
    .login-tabs-section .nav-pills .show>.nav-link {
        color: #fff;
        background: rgb(78, 119, 241)
    } */

    .login-tabs-section-border {
        /* border: 1px solid #ffffff; */
        background: linear-gradient(to left, #3f52af, #478ebd);

        width: 80%;
        padding: 60px 30px;
        margin: auto;
        border-radius: 15px;
    }

    .tabs-login-text-1 {
        font-size: 24px;
    }

    .tabs-login-text-2 {
        font-size: 14px;
    }

    .footer-icons-1 {
        font-size: 30px;
        margin-right: 20px;
        color: #FFF;
    }

    .footer-text-2 {
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .footer-text-1 {
        font-size: 14px;
        margin-bottom: 16px;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .top-banner-section {
        min-height: 90vh;

    }

    /* .top-grid-banner-section{
    background-image: url("./images/banner/banner-bg-image.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    
  } */



    .rotating-tabs-heading {
        font-size: 20px;
        font-weight: 700;
        font-size: 2.5rem;
        margin-bottom: 100px !important;
        width: 50%;
        text-align: center;
        margin: auto;
        background: transparent linear-gradient(110deg, #15D390 0%, #ccc 100%) 0% 0% no-repeat padding-box;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    /* .banner-third-section{
    background: #a8ff78; 
    background: -webkit-linear-gradient(to right, #78ffd6, #a8ff78);
    background: linear-gradient(to right, #78ffd6, #a8ff78);
  } */
    .platform-table-section {
        width: 100%;
        height: 600px;
        overflow-y: scroll;
        background: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(2px);
        -webkit-backdrop-filter: blur(2px);
        border: 1px solid rgba(204, 204, 204, 0.075);
        border-bottom: 0px;
        box-shadow: 0 4px 4px rgb(0 0 0 / 5%), 0 1px 6px rgb(0 0 0 / 5%), 0 8px 8px rgb(0 0 0 / 5%), 0 16px 16px rgb(0 0 0 / 5%), 8px 32px 32px rgb(0 0 0 / 2%), 8px 64px 64px rgb(0 0 0 / 2%);
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        padding: 20px 20px 0px 20px;
    }

    .platform-table-section-bottom {
        background: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(2px);
        -webkit-backdrop-filter: blur(2px);
        border: 1px solid rgba(204, 204, 204, 0.075);
        border-top: 0px;
        box-shadow: 0 4px 4px rgb(0 0 0 / 5%), 0 1px 6px rgb(0 0 0 / 5%), 0 8px 8px rgb(0 0 0 / 5%), 0 16px 16px rgb(0 0 0 / 5%), 8px 32px 32px rgb(0 0 0 / 2%), 8px 64px 64px rgb(0 0 0 / 2%);
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 20px 20px 20px 20px;
    }

    .platform-table-section img {
        width: 30px;
        height: 30px;
    }

    .platform-table-section .flex-row {
        padding: 20px 10px;
        border-bottom: 1px solid rgba(99, 97, 97, 0.199);
    }

    .table-data-2 {
        background-color: #15db95;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        padding: 10px 12px;
        font-size: 15px;
        color: #FFF;
    }

    .table-data-3 {
        background-color: #cef8e991;
        justify-content: center;
        align-items: center;
        border-radius: 25px;
        padding: 8px 16px;
        font-size: 14px;
    }

    .table-data-5 {
        font-size: 14px;
    }

    .table-data-41 {
        font-size: 14px;
    }

    .table-data-42 {
        font-size: 16px;
    }

    .table-data-43 {
        font-size: 16px;
        color: #15db95;
    }

    .table-see-more-button {
        background-color: #8a8a8a;
        padding: 10px;
        border: none;
        border-radius: 8px;
        color: #FFF;
    }

    #line-glow-css {
        width: 16%;
        height: 3px;
        background-color: #15db95;
        border-radius: 40px;
    }

    .bannner-text-1 {
        font-family: 'Rubik', sans-serif;
        font-size: 6.5rem;
        color: #15db95;
        font-weight: 100;
        letter-spacing: 8px;
    }

    .bannner-text-2 {
        font-family: 'Roboto', sans-serif;
        font-size: 5.5rem;
        font-weight: 1000;
        letter-spacing: 6px;
        position: relative;
        top: -20px;
    }

    .bannner-text-3 {
        font-size: 16px;
        font-weight: 800;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .bannner-text-4 {
        font-size: 14px;
        margin-top: 20px;
        margin-bottom: 30px;
    }

    .banner-second-section {
        margin-top: 40px;
        margin-bottom: 60px;
    }

    .banner-second-section .card {
        border: 0px;
        height: 150px !important;
        background: transparent;
        /* padding: 20px;
    background-image: url("./images/world1.png"), url("./images/banner/card-bg.png");
    background-position: right bottom, left top;
    background-repeat: no-repeat, no-repeat;
    background-size: 90px 90px, 100% 100%;
    border: 1px solid rgba(99, 97, 97, 0.199); */
    }

    .banner-second-section-card-1 {
        margin-top: 180px;
    }

    .banner-second-section-card-2 {
        margin-top: 120px;
    }

    .banner-second-section-card-3 {
        margin-top: 60px;
    }

    .banner-section-heading {
        font-family: 'Roboto', sans-serif;
        font-size: 2.5rem;
        font-weight: 1000;
        letter-spacing: 1px;
        text-transform: uppercase;
    }



}



/* ============ mobile view ============ */
@media(max-width: 991px) {
    .email-ellipsis-css {
        white-space: nowrap;
        width: 50vw;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .register-bg-styling-css .min-vh-100 {
        min-height: auto !important;
        padding-top: 100px;
    }

    .login-bg-styling-css .min-vh-100 {
        min-height: auto !important;
        padding-top: 100px;
    }

    .card-logo-image {
        width: 60px;
        height: 60px;
        margin-bottom: 20px;
    }

    .banner-timeline-section .card-image {
        width: 102px;
        height: 102px;
        margin: auto;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px -1px 23px #C5C252;
        border: 1px solid #ffffff;
        border-radius: 30px;
        opacity: 1;
        padding: 20px;
        /* margin-top: -100px; */
        margin-bottom: 40px;
    }

    .button-dashboard-table-back {
        background: transparent;
        font-size: 30px;
        border: 1px solid rgba(0, 0, 0, 0.411);
        border-radius: 50%;
        padding: 2px 6px;
        line-height: 30px;
        cursor: pointer;
        width: fit-content;
    }

    #mobile-close-arrow-left {
        display: none !important;
    }

    #mobile-close-arrow-right {
        display: none !important;
    }

    .glowing-button6 {
        position: relative;
        background-color: #15db95;
        box-shadow: 0 0 10px 0 #15db95;
        border: 2px solid #15db95;
        padding: 8px 20px !important;
        background-color: transparent;
        transition: .4s;
        font-size: 14px;
        cursor: pointer;
        color: #15db95;
        z-index: 0;
    }

    .navbar-brand img {
        width: 80px;
    }

    .navbar-brand-footer img {
        width: 80px;
        height: auto;
    }

    .timeline-image-arrow {
        display: none;
    }

    .banner-followus-section i {
        font-size: 42px;
        background: #cac5318f;
        /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #f3f9a7b6, #cac531be);
        /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #f3f9a7b6, #cac531be);
        /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        line-height: 66px;
    }

    .follow-us-text-1 {
        font-size: 15px;
        text-transform: uppercase;
        font-weight: 900;
    }

    .banner-followus-section .card {
        background: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(2px);
        -webkit-backdrop-filter: blur(2px);
        padding: 10px;
        text-align: center;
    }

    .platform-button-top {
        background: #CAC531;
        /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #F3F9A7, #CAC531);
        /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #F3F9A7, #CAC531);
        /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        padding: 4px 20px;
        border: 0px;
        /* position: absolute;
    top: -16px; */
        border-radius: 12px;
        /* left: 33%; */
        width: fit-content;
        margin: auto;
        margin-top: 30px;
    }

    .platform-button-bottom {
        background: #CAC531;
        /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #F3F9A7, #CAC531);
        /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #F3F9A7, #CAC531);
        /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        padding: 4px 20px;
        border: 0px;
        /* position: absolute;
    bottom: -16px; */
        border-radius: 12px;
        /* left: 33%; */
        width: fit-content;
        margin: auto;
        margin-top: 30px;
    }

    .banner-timeline-section .card .card-title {
        font-size: 16px;
        font-weight: 900;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .banner-timeline-section .card .card-text {
        font-size: 15px;
    }

    .banner-timeline-section .card {
        font-family: 'Jost', sans-serif;
        width: 85%;
        margin: auto;
        background: #a8ff78;
        background: -webkit-linear-gradient(to right, #78ffd72f, #a7ff7836);
        background: linear-gradient(to right, #78ffd72f, #a7ff7836);
        border: 1px solid #ffffff23;
        padding: 20px;
        border-radius: 8px;
    }

    .tabs-level-based-section {
        margin-top: 20px;
        font-size: 20px;
        cursor: pointer;
        margin-bottom: 20vh;
    }

    .level-based-button {
        border: 0px;
        background-color: transparent;
        color: #0d6efd;
        text-decoration: underline;
    }

    .uix-tabs__nav .active img {
        width: 110px;
        height: 110px;
        margin-left: -25px;
        margin-top: -18px;
    }

    .uix-tabs__nav img {
        width: 80px;
        height: 80px;
        margin-left: -5px;
    }

    .uix-tabs__content p {
        font-size: 14px;
    }

    .bannner-text-5 {
        font-size: 22px;
        font-weight: 800;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    .card-left-image {
        margin-top: 70px;
        width: 20px;
        height: 140px;
    }

    .heading-right-image {
        display: none;
    }

    .team-view-staked-top-section {
        width: 500px;
        background-color: #e0e1e233;
        padding: 10px 20px;
        border-radius: 4px;
        border-left: 5px solid #15db95 !important;
        /* position: absolute;
    right: 30px;
    top: 30px; */
    }

    .banner-video-1 {
        width: 100%;
        height: 100%;
    }

    .total-level-text-1 {
        font-size: 12px;
        margin-bottom: 0px;
        line-height: 16px;
    }

    .total-level-text-2 {
        font-size: 12px;
        margin-bottom: 0px;
        line-height: 16px;
        font-weight: 600;
    }

    .glow-dots-banner-image-3 {
        width: 400px;
        height: 400px;
        position: absolute;
        top: -150px;
        right: -150px;
        z-index: -1;
    }

    .glow-dots-banner-image-2 {
        width: 60px;
        height: 60px;
        position: relative;
        top: -30px;
        left: 20px;
        z-index: -1;
    }

    .glow-dots-banner-image-1 {
        width: 60px;
        height: 60px;
        position: relative;
        top: 30px;
        left: 20px;
    }

    .dashboard-profile-staked-2 .nav-link {
        padding: 0px;
        text-decoration: underline;
        font-size: 14px;
    }

    .dashboard-profile-staked-2 .nav-pills .nav-link.active,
    .dashboard-profile-staked-2 .nav-pills .show>.nav-link {
        color: #0d6efd;
        background-color: transparent;
    }

    .form-control-stake-deposit-address,
    .form-control-stake-deposit-address:focus {
        width: 80%;
        margin: auto;
        box-shadow: #000 0px 15px 20px -20px;
        padding: 1rem 0.75rem;
        font-size: 0.9rem;
        border: 1px solid #8a8a8a8e;
        background: white;
        color: #000;
    }

    .dashboard-profile-text-6 {
        margin: auto;
        font-size: 13px;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    }

    .dashboard-profile-text-5 {
        font-size: 28px;
        font-weight: 600;
        letter-spacing: 1px;
    }

    .metamaskModal .btn-close {
        box-shadow: none !important;
    }

    .metamaskModal .modal-content {
        background: linear-gradient(to right, #3f52af, #478ebd);
    }

    .metamaskModal-inner-section {
        background: linear-gradient(to left, #3f52af, #478ebd);
        border: 1px solid #8a8a8a;
        padding: 10px;
        margin: 30px 0px;
        border-radius: 6px;
    }

    .metamaskModal-inner-section img {
        width: 50px;
        height: 50px;
    }

    .metamaskModal-inner-section h5 {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #fff;
    }

    .metamaskModal {
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }

    .metamaskModal .modal-title {
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 600;
        color: #fff;
    }

    .dashboard-profile-text-4 {
        font-size: 14px;
        color: rgb(0, 119, 255) !important;
        text-decoration: underline;
    }

    .dashboard-profile-text-3 {
        font-size: 13px;
        margin-bottom: 6px;
    }

    .dashboard-profile-text-2 {
        font-size: 14px;
        margin-bottom: 6px;
    }

    .dashboard-profile-staked-2 .card {
        border-bottom: 6px solid #15db95;
        background-color: #e0e1e233;
    }

    .dashboard-profile-text-2 {
        font-size: 14px;
        font-weight: 600;
    }

    .dashboard-profile-text-1 {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
    }

    .dashboard-profile-staked-1 {
        background-color: #e0e1e233;
        padding: 20px 20px;
        border-radius: 4px;
        border-left: 5px solid #1668dd !important;
    }

    .btn-profile-update {
        background-color: #8a8a8a;
        padding: 8px 20px;
        color: #FFF;
        border: 1px solid #8a8a8a;
        border-radius: 0.25rem;
    }

    .dashboard-profile-section .form-control-address {
        padding: 0.600rem 0.75rem;
        font-size: 0.9rem;
        font-weight: 400;
        line-height: 1.5;
        color: #000;
        background-color: white;
        background-clip: padding-box;
        border-bottom: 1px solid #ced4da !important;
        border-radius: 0rem;
        box-shadow: none !important;
        border: none;
    }

    .dashboard-profile-section .form-control-name {
        padding: 0.600rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #000;
        background-color: white;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        box-shadow: none !important;
        border-right: 0px;
    }

    .avatar-upload {
        position: relative;
        max-width: 205px;
    }

    .avatar-upload .avatar-edit {
        position: absolute;
        right: 12px;
        z-index: 1;
        top: 10px;
    }

    .avatar-upload .avatar-edit input {
        display: none;
    }

    .avatar-upload .avatar-edit input+label {
        display: inline-block;
        width: 34px;
        height: 34px;
        margin-bottom: 0;
        border-radius: 100%;
        background: #15db96;
        border: 1px solid transparent;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        cursor: pointer;
        font-weight: normal;
        transition: all 0.2s ease-in-out;
    }

    .avatar-upload .avatar-edit input+label:after {
        content: "\f067";
        font-family: 'FontAwesome';
        color: #FFF;
        position: absolute;
        top: 4px;
        left: 0;
        right: 0;
        text-align: center;
        margin: auto;
    }

    .avatar-upload .avatar-preview {
        width: 192px;
        height: 192px;
        position: relative;
        border-radius: 100%;
        border: 6px solid #F8F8F8;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    }

    .avatar-upload .avatar-preview>div {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .dashboard-sidenav-section .nav-pills .nav-link.active,
    .dashboard-sidenav-section .nav-pills .show>.nav-link {
        background-color: white;
        color: #000 !important;
        position: relative;
    }

    .dashboard-sidenav-section .nav-link.active::after {
        content: "";
        position: absolute;
        background-color: transparent;
        bottom: 100%;
        right: 0;
        height: 35px;
        width: 35px;
        border-bottom-right-radius: 18px;
        box-shadow: 0 20px 0 0 white;
    }

    .dashboard-sidenav-section .nav-link.active::before {
        content: "";
        position: absolute;
        background-color: transparent;
        top: 100%;
        right: 0;
        height: 35px;
        width: 35px;
        border-top-right-radius: 18px;
        box-shadow: 0 -20px 0 0 white;
    }

    .dashboard-sidenav-section .nav-pills .nav-link.active,
    .dashboard-sidenav-section .nav-pills .show>.nav-link {
        background-color: white;
        color: #000 !important;
        position: relative;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }

    .dashboard-sidenav-section .nav-link.active::after {
        content: "";
        position: absolute;
        background-color: transparent;
        bottom: 100%;
        right: 0;
        height: 35px;
        width: 35px;
        border-bottom-right-radius: 18px;
        box-shadow: 0 20px 0 0 white;
    }

    .dashboard-sidenav-section .nav-link.active::before {
        content: "";
        position: absolute;
        background-color: transparent;
        top: 100%;
        right: 0;
        height: 35px;
        width: 35px;
        border-top-right-radius: 18px;
        box-shadow: 0 -20px 0 0 white;
    }

    .dashboard-navbar-brand img {
        width: 80px;
        height: 80px;
        margin: auto;
        text-align: center;
        margin-bottom: 30px;
        margin-top: 20px;
    }

    .dashboard-sidenav-section .nav-pills .nav-link {
        padding: 16px 16px;
        text-decoration: none;
        font-size: 13px;
        color: white !important;
        display: block;
        margin-top: 0px;
        border-radius: 0px;
        text-align: left;
        margin-left: 10px;

        text-transform: uppercase;

    }

    .dashboard-sidenav-section .nav-link .bi {
        margin-right: 20px;
        font-size: 22px;
        margin-left: 10px;
        position: relative;
        top: 2px;
    }

    .dashboard-main-navbar {
        margin-left: 400px;
    }

    .dashboard-sidenav-section {
        height: 100%;
        width: 0px;
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        /* background: linear-gradient(to right, #3B33D5, #0b0b4d); */
        /* background: linear-gradient(107deg, rgb(255 97 45) 11.1%, rgb(241 191 130) 95.3%); */
        background: linear-gradient(147deg, #3d3423 25.1%, rgb(241 191 130) 95.3%) !important;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        overflow: scroll;
    }

    .dashboard-main-section {
        margin-left: 0px;
        transition: 0.6s;
    }


    .auth-form__btn {
        text-transform: uppercase;
        background: transparent;
        border: 1px solid #EEE;
        padding: 10px 20px;
        width: 40%;
        font-size: 16px;
        color: #FFF;
        text-transform: uppercase;
        letter-spacing: 1px;
        border-radius: 6px;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    }

    .login-banner-tabs-section {
        min-height: 100vh;
    }

    .login-banner-tabs-section ::placeholder {
        color: #FFF
    }

    .form-control-login {
        display: block;
        width: 50%;
        margin: auto;
        padding: 0.675rem 0.75rem;
        font-size: 1.1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #FFF;
        background-color: #15db95;
        background-clip: padding-box;
        border: none !important;
        outline: none !important;
        border-radius: 0.25rem;
    }

    .tabs-automatic-login-button {
        background: rgb(78, 119, 241);
        padding: 10px 20px;
        font-size: 16px;
        color: #FFF;
        text-transform: uppercase;
        letter-spacing: 1px;
        border-radius: 6px;
        border: none;
        box-shadow: #011f4a 0px 25px 50px -12px;

    }

    .login-tabs-section .nav-pills {
        background: rgb(78, 119, 241);
        width: max-content;
        margin: auto;
        padding: 5px 5px;
        border-radius: 50px;
        box-shadow: #011f4a 0px 25px 50px -12px;


    }

    .login-tabs-section .nav-pills .nav-link {
        color: #FFF;
        padding: 6px 20px;
        border-radius: 50px;
        font-size: 12px;
    }

    .login-tabs-section .nav-pills .nav-link.active,
    .login-tabs-section .nav-pills .show>.nav-link {
        color: #fff;
        background-color: #15db95;
    }

    .login-tabs-section-border {
        background: linear-gradient(to left, #3f52af, #478ebd);

        /* border: 1px solid #ffffff; */
        padding: 30px 30px;
        margin: auto;
        border-radius: 15px;
    }

    .tabs-login-text-1 {
        font-size: 24px;
    }

    .tabs-login-text-2 {
        font-size: 14px;
    }

    .footer-icons-1 {
        font-size: 30px;
        margin-right: 20px;
        color: #FFF;
    }

    .footer-text-2 {
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .footer-text-1 {
        font-size: 14px;
        margin-bottom: 16px;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .top-banner-section {
        min-height: 90vh;

    }

    .rotating-tabs-heading {
        font-size: 20px;
        font-weight: 700;
        font-size: 2.5rem;
        margin-bottom: 100px !important;
        width: 50%;
        text-align: center;
        margin: auto;
        background: transparent linear-gradient(110deg, #15D390 0%, #ccc 100%) 0% 0% no-repeat padding-box;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }


    .platform-table-section {
        width: 100%;
        height: 600px;
        overflow-y: scroll;
        background: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(2px);
        -webkit-backdrop-filter: blur(2px);
        border: 1px solid rgba(204, 204, 204, 0.075);
        border-bottom: 0px;
        box-shadow: 0 4px 4px rgb(0 0 0 / 5%), 0 1px 6px rgb(0 0 0 / 5%), 0 8px 8px rgb(0 0 0 / 5%), 0 16px 16px rgb(0 0 0 / 5%), 8px 32px 32px rgb(0 0 0 / 2%), 8px 64px 64px rgb(0 0 0 / 2%);
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        padding: 10px 10px 0px 10px;
    }

    .platform-table-section-bottom {
        background: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(2px);
        -webkit-backdrop-filter: blur(2px);
        border: 1px solid rgba(204, 204, 204, 0.075);
        border-top: 0px;
        box-shadow: 0 4px 4px rgb(0 0 0 / 5%), 0 1px 6px rgb(0 0 0 / 5%), 0 8px 8px rgb(0 0 0 / 5%), 0 16px 16px rgb(0 0 0 / 5%), 8px 32px 32px rgb(0 0 0 / 2%), 8px 64px 64px rgb(0 0 0 / 2%);
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 20px 20px 20px 20px;
    }

    .platform-table-section img {
        width: 20px;
        height: 20px;
    }

    .platform-table-section .flex-row {
        padding: 20px 10px;
        border-bottom: 1px solid rgba(99, 97, 97, 0.199);
    }

    .table-data-2 {
        background-color: #15db95;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        padding: 4px 6px;
        font-size: 10px;
        color: #FFF;
    }

    .table-data-3 {
        background-color: #cef8e991;
        justify-content: center;
        align-items: center;
        border-radius: 25px;
        padding: 8px 10px;
        font-size: 12px;
        display: flex;
    }

    .table-data-5 {
        font-size: 12px;
    }

    .table-data-4 {
        display: flex;
    }

    .table-data-41 {
        font-size: 12px;
    }

    .table-data-42 {
        font-size: 12px;
    }

    .table-data-43 {
        font-size: 12px;
        color: #15db95;
    }

    .table-see-more-button {
        background-color: #8a8a8a;
        padding: 6px;
        border: none;
        border-radius: 8px;
        color: #FFF;
    }

    #line-glow-css {
        width: 16%;
        height: 3px;
        background-color: #15db95;
        border-radius: 40px;
    }

    .bannner-text-22 {
        font-size: 14px;
    }

    .bannner-text-1 {
        font-family: 'Rubik', sans-serif;
        font-size: 4.5rem;
        color: #15db95;
        font-weight: 100;
        letter-spacing: 8px;
    }

    .bannner-text-2 {
        font-family: 'Roboto', sans-serif;
        font-size: 3.5rem;
        font-weight: 1000;
        letter-spacing: 6px;
        position: relative;
        top: -20px;
    }

    .bannner-text-3 {
        font-size: 16px;
        font-weight: 800;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .bannner-text-4 {
        font-size: 14px;
        margin-top: 20px;
        margin-bottom: 30px;
    }

    .banner-second-section {
        margin-top: 40px;
        margin-bottom: 60px;
    }

    .banner-second-section .card {
        border: 0px;
        height: 150px !important;
        background: transparent;
        /* padding: 20px;
    background-image: url("./images/world1.png"), url("./images/banner/card-bg.png");
    background-position: right bottom, left top;
    background-repeat: no-repeat, no-repeat;
    background-size: 90px 90px, 100% 100%;
    border: 1px solid rgba(99, 97, 97, 0.199); */
    }


    .banner-section-heading {
        font-family: 'Roboto', sans-serif;
        font-size: 2.5rem;
        font-weight: 1000;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
}

/* ============ Common CSS ============ */



.wallet-table-section-tabs table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
}

.wallet-table-section-tabs table tbody {
    padding: .35em;
    border-top: 0px !important;
}

.wallet-table-section-tabs table th {
    background-color: #15db95 !important;
}

.wallet-table-section-tabs table tbody tr {
    font-size: 14px;
    border: 1px solid #ccc;
}

.wallet-table-section-tabs table th:nth-child(1) {
    border-top-left-radius: 8px;
}

.wallet-table-section-tabs table th:last-child {
    border-top-right-radius: 8px;
}

.wallet-table-section-tabs table th,
.wallet-table-section-tabs table td {
    padding: .625em;
    text-align: center;
}

.wallet-table-section-tabs table th {
    font-size: .85em;
    letter-spacing: .1em;
    text-transform: uppercase;
}

@media screen and (max-width: 600px) {
    .wallet-table-section-tabs table {
        border: 0;
    }

    .wallet-table-section-tabs table caption {
        font-size: 1.3em;
    }

    .wallet-table-section-tabs table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .wallet-table-section-tabs table tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: .625em;
    }

    .wallet-table-section-tabs table td {
        display: block;
        font-size: .8em;
        text-align: right;
    }

    .wallet-table-section-tabs table td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }

    .wallet-table-section-tabs table td:last-child {
        border-bottom: 0;
    }
}

.tableFixHead {
    overflow: auto;
    height: 500px;
}

.tableFixHead thead th {
    position: sticky;
    top: 0;
}


.swiper {
    padding-bottom: 100px;
}

.swiper .card {
    padding: 50px 10px;
    background: transparent linear-gradient(92deg, #00000000 0%, #15db95 100%) 0% 0% no-repeat padding-box;
    border: 0px !important;
    border-radius: 20px;

}

.swiper .card img {
    width: 100px;
    height: 100px;
    margin: auto;
}

.swiper .card .card-title {
    font-size: 20px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-weight: 800;
    text-transform: uppercase;
}

.swiper .card .card-text {
    font-size: 15px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.swiper-button-prev {
    position: absolute;
    top: 90%;
    left: 46%;
    border: 1px solid #15D390;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #15D390;
    opacity: 1;
}

.swiper-button-next {
    position: absolute;
    top: 90%;
    right: 46%;
    border: 1px solid #15D390;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #15D390;
    opacity: 1;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
    opacity: 1;
    pointer-events: all;
}


.connectwallet-btn {
    background: #d79332;
    background: linear-gradient(to bottom, #d79332 0%, #15db95 19%, #f8e852 46%, #f8e852 63%, #15db95 83%, #e1aa3d 100%);
    padding: 10px 20px !important;
    border-radius: 8px;
}




/* glowing button 6 */


.glowing-button6::before {
    content: '';
    width: 100%;
    height: calc(50% + 1px);
    background-color: #15db95;
    position: absolute;
    left: 0;
    top: 0;
    border: 2px solid #15db95;
    transition: .4s;
    box-shadow: 0 0 1px 0 #15db95;
    z-index: -1;
    width: 10px;
    height: 10px;
    top: -5px;
    left: -5px;
    background-color: black;
    border-radius: 50%;
}

.glowing-button6::after {
    content: '';
    width: 100%;
    height: calc(50% + 1px);
    background-color: #15db95;
    position: absolute;
    right: 0;
    bottom: 0;
    border: 2px solid #15db95;
    transition: .4s;
    box-shadow: 0 0 1px 0 #15db95;
    z-index: -1;
    width: 10px;
    height: 10px;
    bottom: -5px;
    right: -5px;
    background-color: black;
    border-radius: 50%;
}

.glowing-button6:hover::before {
    left: calc(100% - 5px);
}

.glowing-button6:hover::after {
    right: calc(100% - 5px);
}


/*-------------vertical-tree-view------------*/
.vertical-tree {
    padding-top: 40px;
    padding-bottom: 40px;
}

.vertical-tree ul {
    padding-left: 30px;
}

.vertical-tree li {
    margin: 0px 0;
    list-style-type: none;
    position: relative;
    padding: 20px 5px 0px 5px;
}

.vertical-tree li::before {
    content: '';
    position: absolute;
    top: 0;
    width: 1px;
    height: 100%;
    right: auto;
    left: -20px;
    border-left: 2px solid #ccc;
    bottom: 50px;
}

.vertical-tree li::after {
    content: '';
    position: absolute;
    top: 34px;
    width: 25px;
    height: 20px;
    right: auto;
    left: -20px;
    border-top: 2px solid #ccc;
}

.vertical-tree li a {
    background-color: #9fffdd;
    color: #222;
    padding: 10px;
    display: inline-block;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    /* height: 50px; */
    width: 200px;
    word-wrap: break-word;
    line-height: 40px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    border-radius: 4px;
}

.vertical-tree>ul>li::before,
.vertical-tree>ul>li::after {
    border: 0;
}

.vertical-tree li:last-child::before {
    height: 34px;
}

/* .vertical-tree li a:hover, 
.vertical-tree li a:hover+ul li a {
    background-color: #5a8dee;
    color: #fff;
    border: 1px solid #5a8dee;
} */
.vertical-tree li a:hover+ul li::after,
.vertical-tree li a:hover+ul li::before,
.vertical-tree li a:hover+ul::before,
.vertical-tree li a:hover+ul ul::before {
    border-color: #15db95;
}

#more {
    display: none;
}

#myBtn {
    background-color: transparent;
    border: 0px;
    color: #FFF;
    font-size: 14px;
}

.sidenav-icon-size-css {
    font-size: 24px;
    margin-right: 0.4em;
}

.confirm-cancel-button-css {
    background: rgba(78, 119, 241, 0.9);
    border-radius: 2em;
    padding: 1em;
    width: 150px;
    color: #fff;
}

.confirm-cancel-button-css:hover {
    background: rgba(78, 119, 241, 0.9);
    border-radius: 2em;
    padding: 1em;
    width: 150px;
    color: #fff;
}

.unstake-button-css {
    background: rgba(78, 119, 241, 0.9);
    border-radius: 1em;
    padding: 1em;
    width: 150px;
    color: #fff;
}

.unstake-button-css:hover {
    background: rgba(78, 119, 241, 0.9);
    border-radius: 1em;
    padding: 1em;
    width: 150px;
    color: #fff;
}

/*==============dashboard-css==================*/
@import url("https://fonts.googleapis.com/css?family=Quattrocento+Sans");
@import url("https://fonts.googleapis.com/css?family=Oswald");



.outer-circle {
    background-image: linear-gradient(#89b3ee, #3172c7, #203c8ee6);
    width: 250px;
    height: 250px;
    border-style: solid;
    border-color: transparent;
    border-radius: 50%;
    border-width: 0;
    -webkit-animation: rotateCircle 2s linear infinite;
    animation: rotateCircle 2s linear infinite;
    padding: 2px;
    display: table;
}

.outer-circle .inner-circle {
    background: #fff;
    height: 250px;
    width: 250px;
    border-style: solid;
    border-color: transparent;
    border-radius: 50%;
    border-width: 1px;
    animation: rotateCircle 2s linear infinite reverse;
    display: table;
    text-align: center;
}

.outer-circle .text {
    vertical-align: middle;
    display: table-cell;
    padding: 0 20px;
    text-align: center;
    color: #000;
    font-size: 14px;
    font-weight: 600;

}

@-webkit-keyframes rotateCircle {
    0% {
        transform: rotate(0);
    }

    25% {
        transform: rotate(90deg);
    }

    50% {
        transform: rotate(180deg);
    }

    75% {
        transform: rotate(270deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotateCircle {
    0% {
        transform: rotate(0);
    }

    25% {
        transform: rotate(90deg);
    }

    50% {
        transform: rotate(180deg);
    }

    75% {
        transform: rotate(270deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Example CSS */
.account-balance-container {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    font-size: 20px;
    color: #333;
}

.account-balance-label {
    font-weight: bold;
}

.account-balance-amount {
    font-size: 28px;
    color: #008080;
    margin-top: 10px;
}


/* TABLE CSS */


/* Table.css */
.table {
    width: 100%;
    border-collapse: collapse;
}

.table th,
.table td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
}

.table th {
    background-color: #f2f2f2;
}

.table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

.table tbody tr:hover {
    background-color: #ddd;
}

.navigationButtons li {
    list-style: none;
}

.navigationButtons {
    display: flex;
    margin: auto;
    gap: 20px;
}

.pagination-buttons {
    display: flex;
}


/*=========================SIGNUP==========================================*/

@import url('https://fonts.googleapis.com/css?family=Dosis:300,400,500,600,700&subset=latin-ext');

#signupsection .wrapper {
    width: 1%;
    height: 100vh;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #156bdb, #345da0);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #3f52af, #478ebd);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    display: table-cell;
    vertical-align: middle;
    font-family: 'Dosis', sans-serif;
}

#signupsection .sign-panels {
    width: 650px;
    background: linear-gradient(to left, #3f52af, #478ebd);
    padding: 40px 80px;
    margin: 40px auto;
    border-radius: 20px;
    text-align: center;
}

#signupsection .login,
#signupsection .signup {
    position: relative;
}


#signupsection .title {
    color: #ffffff;
}

#signupsection .title span {
    display: block;
    font-size: 46px;
    font-weight: bold;
}

#signupsection .title p {
    font-size: 20px;
    font-weight: 500;
}

#signupsection .sign-panels input {
    width: 100%;
    display: block;
    margin-bottom: 15px;
    height: 50px;
    border-radius: 50px;
    border: none;
    background: #ededed;
    text-align: center;
    padding: 10px;
    font-size: 15px;
    color: #000000;
    font-weight: 500;
}

#signupsection .sign-panels input:focus {
    outline: none;
}

#signupsection .sign-panels input[type="checkbox"] {
    display: none;
}

#signupsection .sign-panels input[type="checkbox"]+label {
    display: block;
    width: 50%;
    text-align: left;
    padding-left: 60px;
    cursor: pointer;
    color: #000000;
    font-weight: 500;
    margin-top: 10px;
    float: left;
    height: 50px;
    padding-top: 15px;
}

#signupsection .sign-panels input[type="checkbox"]+label:before {
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    background: #dbdbdb;
    position: absolute;
    left: 30px;
    border-radius: 50%;
    border: 2px solid white;
    box-shadow: 0 0 0 5px #ededed;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

#signupsection .sign-panels input[type="checkbox"]:checked+label:before {
    background: #4e77f1e6;
    box-shadow: 0 0 0 5px #4e77f1e6;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

#signupsection .btn-signin {
    display: inline-block;
    width: 50%;
    margin-top: 10px;
    height: 50px;
    background: #4e77f1e6;
    border-radius: 50px;
    padding: 11px;
    font-size: 20px;
    color: #fff !important;
    text-decoration: none;
    font-weight: 500;
    border: none;
    box-shadow: none;
    cursor: pointer;
}

#signupsection .btn-reset,
#signupsection .btn-member,
#signupsection .btn-fade {
    font-size: 19px;
    font-weight: 500;
    color: #ffffff !important;
    display: block;
    /*width: 210px;*/
    margin: 30px auto 0;
    text-decoration: none;

}

#signupsection .btn-member {
    margin-top: 15px;
}

#signupsection .btn-reset .fa,
#signupsection .btn-member .fa {
    margin-left: 6px;
}

#signupsection .notification p {
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
}

#signupsection .notification span {
    color: #ffffffe6;
}

#signupsection .error {
    display: block;
    color: #ffffffe6;
    font-size: 20px;
    font-weight: 600;
    margin: 15px 0;
}

@media screen and (max-width: 768px) {
    #signupsection .sign-panels {
        width: 90%;
        padding: 40px;
    }
}

@media screen and (max-width: 570px) {
    #signupsection .sign-panels {
        padding: 40px 20px;
    }

}

@media screen and (max-width: 480px) {
    #signupsection .sign-panels input[type="checkbox"]+label {
        width: 100%;
    }

    #signupsection .btn-signin {
        width: 80%;
    }

    #signupsection .title span {
        font-size: 36px;
    }

}

/*==================================COPY-QR-TEXT======================================*/


#qrcodeModal .label {
    padding: 10px;
    font-size: 20px;
    color: #ffffff;
}

#qrcodeModal .copy-text {

    padding: 5px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
}

#qrcodeModal .copy-text input.text {
    padding: 5px;
    font-size: 15px;
    color: #555;
    border: none;
    outline: none;
}

#qrcodeModal .copy-text button {
    padding: 10px;
    background: #5784f5;
    color: #fff;
    font-size: 15px;
    border: none;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
}

#qrcodeModal .copy-text button:active {
    background: #809ce2;
}

#qrcodeModal .copy-text.active button:before,
#qrcodeModal .copy-text.active button:after {
    display: block;
}

.qrcode-size {
    width: 200px;
    height: 180px;
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<crypto-transaction>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
.custom-crypto-transaction {

    margin: 0px -12px;
    padding: 50px;
}

.custom-insidecrypto h2 {
    color: #BC9018;
    margin-bottom: 20px;
}

.MdOut-icon {
    font-size: 40px;
    color: #BC9018;
}

.custom-navcrypto ul {
    margin-bottom: 60px !important;
}

.custom-navcrypto button {
    color: black;
}

.custom-navcrypto button:hover {
    color: black;
}

.custom-navcrypto button.active {
    background-color: #BC9018 !important;
    color: white !important;
}

.custom-select {
    background-color: transparent;
    margin-bottom: 30px;
    padding: 8px 30px 8px 8px;
    border-radius: 5px;
}


.custom-datascroll {
    overflow: auto;
}

.custom-datascroll th {
    font-size: 13px;
    font-weight: 500;
    white-space: nowrap;
}
.custom-datascroll td {
  white-space: nowrap;
}

.custom-transaction-pagination .active>.page-link {
    background-color: #BC9018;
    border: 1px solid #BC9018;
}

.page-link:focus {
    box-shadow: none;
}

/* .custom-assettransaction .custom-transaction-pagination .active>.page-link{
    background-color: #BC9018 !important;
    border: 1px solid #BC9018 !important;
} */
@media (max-width:991px) {
    .custom-crypto-transaction {
        padding: 0px;
    }

    .custom-insidecrypto h2 {
        font-size: 17px;
    }

    .MdOut-icon {
        font-size: 27px;
    }

    .custom-navcrypto button {
        font-size: 12px;
    }

    .custom-navcrypto ul {
        margin-bottom: 30px !important;
    }
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<custom-tradehistory>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
.custom-tradehistory {
    padding: 50PX 50px;
    margin:0px -12px;
}

.custom-inside-trade {
    border: 0px solid #D2B14A;
    padding: 10px;
    /* box-shadow: 0px 0px 0px gray; */
    box-shadow: 6px 2px 16px 0 #d9bf7080, -6px -2px 16px 0 #a58c4180;
}

.custom-inside-trade h4 {
    text-align: center;
    color: #BC9018;
}

.custom-tabbtns {
    border-bottom: 2px solid #D2B14A;
    margin-bottom: 10px;
}

.custom-tabbtns button {
    background-color: transparent !important;
    color: black;
}

.custom-tabbtns button:hover {
    background-color: transparent !important;
    color: black !important;
}

.custom-tabbtns button.active {
    color: #BC9018 !important;
}

.custom-tradenavtabs ul {
    display: flex;
    justify-content: space-around;
}

.custom-accordian-body {
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
}

.custom-accordian-body.active {
    border: 1px solid #BC9018;
}

.custom-todaybtn {
    font-size: 15px;
}

.custom-todaybtn:focus {
    background-color: transparent !important;
    box-shadow: none !important;
}

.custom-todaybtn:not(.collapsed) {
    background-color: transparent !important;
    color: none !important;
}

.custom-today-accordian-1 p {
    /* text-align: start !important; */
    font-size: 12px !important;
}

.custom-trade-rightsec {

    padding: 10px;
    border-radius: 14px;
    background: rgba(111, 111, 111, 0.17);
}

.custom-trade-rightsec h3 {
    font-size: 15px;
}

.custom-timetrade {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.custom-timetrade p {
    font-size: 12px;
}

.top-time {
    border-top: 1px solid black;
}

.last-status {
    border-top: 1px solid black;
    padding: 10px;
}

.fully-custom {
    color: #DF4C03;
}

.custom-scroll-trade {
    height: 450px;
    overflow: auto;
}

.custom-trade-buy-1 button.active {
    background: var(--primary-color) !important;
    color: white !important;
}

.custom-trade-buy-1 button {
    color: black !important;
}

@media (max-width:991px) {
    .custom-tradehistory {
        padding: 0px;
    }

    .custom-today-accordian-1 p {
        /* text-align: start !important; */
        font-size: 10px !important;
    }

    /* .custom-scroll-trade {
        height: 100%;
    } */
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<custom-assettransaction>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
.custom-assettransaction {
    margin:0px -12px;
    padding: 50px;
}

.custom-inside-asset-transaction h2 {
    /* font-size: 20px; */
    color: #BC9018;
}

.custom-assetcroll {
    overflow: auto;
    text-align: center;
    margin-top: 30px;
    border: 0px solid #D2B14A;
    box-shadow: 6px 2px 16px 0 #d9bf7080, -6px -2px 16px 0 #a58c4180;
}

.custom-assetcroll th {
    padding: 13px 20px !important;
    font-weight: 500;
}

.custom-assetcroll td {
    padding: 10px 20px !important;
    font-size: 12px;
    white-space: nowrap;
    vertical-align: middle;
}

.custom-tablefirst {
    margin: 20px;
    background: rgba(217, 217, 217, 0.13);
}
.custom-ress {
    width: 100%;
    background: rgba(208, 208, 208, 0.38);
    border-radius: 33px;
    height: 44px;
    padding: 13px 5px 0px 5px;
    margin-bottom: 0px;
}
.custom-table-row.active {
    border: 1px solid #BC9018;
    border-radius: 20px !important;
}
.token-data {
    display: table-caption;
}
.custom-assetbtn {
    border: 0px;
    padding: 10px 20px;
    border-radius: 5px;
    background: #BC9018;
    color: white;
}
.custom-view {
    font-size: 15px;
}
@media (max-width:991px) {
    .custom-bottlefull {
        width: 15%;
    }

    .custom-assettransaction {
        padding: 0px;
    }
}
@media (min-width:991px) and (max-width:1024px) {
    .custom-assettransaction, .custom-assetdash-board, 
    .custom-crypto-wallet, 
    .custom-crypto-transaction, .custom-tradehistory{
        /* margin:0px -12px;
        padding: 50px 100px !important; */
        margin-left:30px !important;
    }
    .custom-profile {
        padding: 40px 0px !important;
    }
    .email-ellipsis-css {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<custom-assetdash-board>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
.custom-assetdash-board {
    margin: 0px -12px;
    padding: 50px;
}

.custom-assetdash-board .custom-asset-contact {
    border: 0px solid blue;
}

.custom-assetdash-board .custom-asset-contact h2 {
    color: #D0B000;
}

.inside-custom-asset-contact {
    padding: 40px 25px;
    background: linear-gradient(167deg, rgba(135, 135, 135, 0.31) 7.74%, rgba(90, 90, 90, 0.17) 90.43%);
    color: #000;
    border-radius: 15px;
}

.reedem-order-ouy-line {
    border: 0px solid #D2B14A;
    padding: 10px 15px;
    box-shadow: 6px 2px 16px 0 #d9bf7080, -6px -2px 16px 0 #a58c4180;
}

@media (max-width:991px) {
    .custom-assetdash-board {
        padding: 50px 0px;
    }
}

@media (min-width:280px) and (max-width:991px) {
    .custom-assetdash-board .row>* {
        padding: 0px !important;
    }
}

.asset-btn-1 {
    background-color: white !important;
    color: #BC9018 !important;
    border: 1px solid #BC9018;
}

.asset-btn-1.active {
    background-color: #BC9018 !important;
    color: white !important;
}

.asset-btn-1:focus-visible {
    box-shadow: none;
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<cryptowallet>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
.custom-crypto-wallet {
    border: 0px solid red;
    margin: 0px -12px;
    padding: 50px;
}

.custom-inside-crypto-wallet h2 {
    color: #BC9018;

}

.custom-inside-crypto-wallet button {
    color: white;
    background-color: #bc9018;
    margin-left: 10px;
}

.custom-inside-crypto-wallet button:hover {
    color: white !important;
}

.custom-inside-crypto-wallet button.active {
    color: white !important;
    background: rgba(223, 76, 3, 0.27) !important;
}

.custom-inside-crypto-wallet-nav h4 {
    display: inline-block;
    background-color: #BC9018;
    padding: 5px 20px;
    color: white;
    border-radius: 5px;
    font-size: 15px;
}

.custom-symbol-table {
    border: 0px solid red;
}

.custom-symbol-table th {
    background: #BC9018;
    font-weight: 500;
    padding: 20px 0px;
    font-size: 14px;
}

.custom-tablefirst th {
    background: #BC9018;
    font-weight: 500;
    white-space: nowrap;
}

.custom-datascroll {
    border: 0px solid #D2B14A;
    box-shadow: 6px 2px 16px 0 #d9bf7080, -6px -2px 16px 0 #a58c4180;
}

.custom-datascroll th {
    background: #BC9018;
    font-weight: 500;
    padding: 13px 20px;
    font-size: 16px;

}

.custom-inside-crypto-wallet-table img {
    width: 35px !important;
    height: 35px !important;
}

/* .custom-inside-crypto-wallet-table ::-webkit-scrollbar {max-height: 10%; height: 10%;} */
.custom-inside-crypto-wallet-table {
    overflow: auto;
    border: 0px solid #D2B14A;
    box-shadow: 6px 2px 16px 0 #d9bf7080, -6px -2px 16px 0 #a58c4180;
}

/* .inside-table-scroll{
    overflow-y: scroll;
    height: 200px;
} */
.tableFixHead {
    overflow: auto;
    height: auto;
    z-index: -9;
}

.tableFixHead thead th {
    position: sticky;
    top: 0;

}

.custom-inside-crypto-wallet-table td {
    font-size: 12px;
}

.custom-wallet-deposite {
    border-radius: 5px;
    padding: 5px;
    background-color: #bc9018;
    color: white;
}
.wallet-loader{
    color: #bc9018 !important;
}

@media (max-width:991px) {
    .custom-crypto-wallet {
        padding: 50px 0px;
    }

    .wallet-response {
        display: block !important;
    }

    .custom-inside-crypto-wallet-table img {
        width: 35px !important;
        height: 35px !important;
    }

    .custom-inside-crypto-wallet-table th {
        padding: 20px;
    }

    /* .custom-inside-crypto-wallet-table td {

    } */
   
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<custom-profile>>>>>>>>>>>>>>>>>>>>>>> */
.custom-profile {
    border: 0px solid red;
    padding: 40px;
}

.custom-inside-profile {
    border: 0px solid #D2B14A;
    /* box-shadow: 0px 0px 3px gray; */
    border-radius: 10px;
    padding: 10px;
    box-shadow: 6px 2px 16px 0 #d9bf7080, -6px -2px 16px 0 #a58c4180;
    margin-top: 35px;
}

.custom-inside-profile img {
    width: 9%;
}

.custom-inside-profile button {
    border: 1px solid var(--primary-color);
    padding: 0px 0px;
    text-transform: capitalize;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border-radius: 10px;
    font-size: 16px;
    text-transform: uppercase;
    min-width: 120px;
    min-height: 40px;
}

.custom-account-setting {
    border: 0px solid blue;
    padding: 0px;
}

.custom-acccount-name {
    border: 0px solid #D2B14A;
    padding: 10px 10px;
    margin-bottom: 10px;
    /* box-shadow:0px 0px 3px gray; */
    border-radius: 10px;
    box-shadow: 6px 2px 16px 0 #d9bf7080, -6px -2px 16px 0 #a58c4180;
    margin-top: 35px;
}

.custom-acccount-name img {
    width: 9%;
}

@media(max-width:991px) {
    .custom-profile {
        padding: 0px;
    }

    .custom-inside-profile img {
        width: 15%;
        margin-bottom: 10px;
    }

    .custom-acccount-name img {
        width: 15%;
        margin-bottom: 10px;
    }

}

.profile-button {
    border: 1px solid var(--primary-color);
    padding: 5px 15px;
    text-transform: capitalize;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border-radius: 10px;
    font-size: 16px;
    text-transform: uppercase;
}

.custom-modal-update-profile {
    max-width: 90%;
}

.kyc-verification {
    max-width: 100% !important;
}

@media (max-width:991px) {
    .custom-edit-profile-pop-up {
        padding: 0px;
    }

    .variable-width {
        width: 100% !important;
    }

    .custom-modal-update-profil1 {
        width: 100% !important;
    }

    .kyc-verification {
        width: 100% !important;
    }
    .custom-modal-update-profile {
        max-width: 100%;
    }
}

.react-tel-input .form-control {
    width: 100% !important;
}

.invoice-color {
    color: #BC9018;
}

.dowload-con-1 {
    padding: 10px 0px;
}

@media all and (min-width: 992px) {
    /* .inside-asset-1{
        min-height:400px;
    } */
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<pandi>>>>>>>>>>>>>>>>>>>>>> */
@media (max-width:658px) {
    .custom-assetcroll td {
        padding: 10px 8px !important;
        font-size: 12px;
    }

    .custom-today-accordian-1 p {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        white-space: nowrap !important;
        text-align: start !important;
    }

    .new-wrap-content-child td {
        white-space: nowrap !important;
    }

    .address-1-btn {
        padding: 10px 16px;
        width: 100%;
    }

    .new-btn-ctn-01 {
        overflow: hidden !important;
        text-overflow: ellipsis !important;
    }

    .new-qr-code-btn-01 {
        max-width: 55% !important;
        height: auto;
    }

    .new-Deposite-sec-ctn-02,
    .new-return-sec-ctn-02 {
        font-size: 19px;
    }

    .new-alt-dep-sec-01 {
        margin-bottom: 10px !important;
    }

    .new-alter-crypto-sec-01 {
        margin-bottom: 10px !important;
    }

    #mobile-three-line-collapse {
        margin-left: 0px !important;
    }
}

.new-qr-code-btn-01 {
    max-width: 25%;

    height: auto;
}
@media (min-width:652px) and (max-width:991px){
    .new-qr-code-btn-01 {
        max-width: 100%;
    
        height: auto;
    }
}
@media (max-width:991px) {
    .custom-calender-btn {
        text-align: center !important;
    }

    /* .new-ctn-inp-select .input-select {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100% !important;

    } */
    .coin-select-1 {
        font-size: 14px;
    }

    .new-frac-bulk-btn {
        display: flex !important;
        justify-content: flex-start !important;
    }

    .new-frac-bulk-btn-child {
        margin-top: 10px;
    }

    .custom-fraction-modal .custom-bulk-order {
        /* padding: 100px 40px; */
        margin: 0px -43px;
    }

    .custom-balance {
        overflow: hidden !important;
        word-break: break-all;
    }
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>>> */
/* ===========Karthick */
.custom-inside-crypto-wallet-table tr {
    vertical-align: middle;
}

.custom-inside-crypto-wallet-table td {
    padding: 10px 20px !important;
    font-size: 14px !important;
}

.custom-symbol-table th {
    padding: 13px 20px !important;
    font-size: 16px !important;
    white-space: nowrap;
}

.submitSpan {
    font-size: 14px;
}

/* ===========Karthick */


/* praveen */